.App {
  overflow: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  padding: 15vh;
  background: linear-gradient(135deg, rgba(213,241,243,1) 0%, rgba(213,214,254,1) 100%);
}

.App-header {
  display: flex;
  
  margin-top: -20vh;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.logos {
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: -5vh;
  margin-bottom: 5vh;
}

.App-title {
  font-size: calc(20px + 15vmin);
  font-family: 'Pacifico', cursive;

  text-align: center;
  font-weight: 500;
  background: linear-gradient(90deg, rgba(5,133,185,1) 0%, rgba(110,185,181,1) 10%, rgba(255,115,209,1) 20%, rgba(230,218,1,1) 30%, rgba(253,171,2,1) 50%, rgba(253,120,89,1) 60%, rgba(255,54,60,1) 70%, rgba(253,123,183,1) 80%, rgba(128,79,204,1) 90%, rgba(3,140,230,1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}