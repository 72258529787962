body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: 'Hello';
  src: local('Hello'), url(./fonts/Hello.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  height: 100%;
  font-family: 'Hello';
  src: local('Hello'), url(./fonts/Hello.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
